/* rails-erb-loader-dependencies */

export const MAP_ID = 'this_map';
const accessToken = window.LEAFLET_JAWG_API.key;
export const MAP_TILES_URL = 'https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=' + accessToken;
export const MAP_TILES_OPTIONS = {
  attribution: '<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  maxZoom: 19,
  subdomains: 'abcd',
  accessToken: accessToken
};

export const DEFAULT_ICON =  L.Icon.extend({
  options: {
      iconSize:     [30, 30],
      iconAnchor:   [0, 10],
      popupAnchor:  [10, 0]
    }
  }
);

export const GEO_MAP_ID = 'geo-json-map';
export const GEO_MAP_DIV = 'div#' + GEO_MAP_ID;

export const NETWORK_MAP_ID = 'network-json-map';
export const NETWORK_MAP_DIV = 'div#' + NETWORK_MAP_ID;

export const HISTORICAL_EVENT_MAP_ID = 'historical-events-json-map';
export const HISTORICAL_EVENT_MAP_DIV = 'div#' + HISTORICAL_EVENT_MAP_ID;

export const MARKER_OPTIONS = {
  radius: 10,
  fillColor: "rgb(28,134,180)",
  color: "#000",
  weight: 1,
  opacity: 1,
  fillOpacity: 0.8
};

export const CLUSTER_OPTIONS = {
  spiderfyOnMaxZoom: true,
  showCoverageOnHover: false,
  zoomToBoundsOnClick: false,
  spiderfyDistanceMultiplier: 5
};
