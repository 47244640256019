import { MAP_ID, MAP_TILES_URL, MAP_TILES_OPTIONS }  from './map_config.js';
import { createMap, removeMap, mapHolderData } from './map_common.js';

$(function () {
  if ($('div.map').length) {
    setUpMap();
  }
});

function setUpMap() {
  var mapId = MAP_ID;
  var latitude = mapHolderData().latitude
  var longitude = mapHolderData().longitude

  if (latitude.length && longitude.length) {
    removeMap(mapId);
    createMap(latitude, longitude, mapId);
  }
}
