// Entry point for the build script in your package.json
import Rails from '@rails/ujs';
Rails.start();

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import("trix")
import("@rails/actiontext")

import 'bootstrap/dist/js/bootstrap';

import './vendor/leaflet.zoom';
import './scripts/map';
import './scripts/location_finder';
import './scripts/network_geo_json';
import './scripts/historical_event_geo_json';
