import { MAP_ID, MAP_TILES_URL, MAP_TILES_OPTIONS, HISTORICAL_EVENT_MAP_ID }  from './map_config.js';

export function addPopups(feature, layer) {
  if (feature.properties && feature.properties.popup_content) {
    var popUp = L.popup({ minWidth: 400 }).setContent(feature.properties.popup_content);
    layer.bindPopup(popUp);
    layer.on('click', function (e) {
      // e = event
      console.log(e);
      // You can make your ajax call declaration here
      //$.ajax(...
    });
  }
}

export function addLatLongClick(map) {
  map.on('click', function(e) {
    alert(e.latlng);
  });
}

export function addLogOfCentre(map) {
  map.on("moveend", function () {
    console.log(map.getCenter().toString());
  });
}

export function addZoomLogger(map) {
  var ZoomViewer = L.Control.extend({
    onAdd: function(){
      var gauge = L.DomUtil.create('div');
      gauge.style.width = '200px';
      gauge.style.background = 'rgba(255,255,255,0.5)';
      gauge.style.textAlign = 'left';
      map.on('zoomstart zoom zoomend', function(ev){
        gauge.innerHTML = 'Zoom level: ' + map.getZoom() + ' center ' + map.getCenter();
      })
      return gauge;
    }
  });

  (new ZoomViewer).addTo(map);
}

export function createMap(latitude, longitude, mapId) {
  var zoomLevel = mapHolderData().zoomLevel;
  var showMarker = mapHolderData().showMarker;
  var mapClass = mapHolderData().mapClass;

  console.log("creating map: " + mapId);
  $( "<div id=\"" + mapId + "\" class=\"" + mapClass + " mb-3 \"></div>" ).insertAfter("#" + mapId + '_holder');

  var map = L.map(mapId);
  var layer = L.tileLayer(MAP_TILES_URL, MAP_TILES_OPTIONS);

  layer.addTo(map);
  map.setView([latitude, longitude], zoomLevel);

 if (showMarker == 'true') {
    var circle = L.circle([latitude, longitude], {
        color: '#55c0c8',
        fillColor: '#55c0c8',
        fillOpacity: 0.5,
        radius: 50
    }).addTo(map);
  }

  console.log("created map: " + mapId);
}

export function removeMap(mapId) {
  console.log("removing map: " + mapId);
  var container = L.DomUtil.get(mapId);

  if (container != null) {
    container.remove();
  }

  if ($('#' + MAP_ID)) {
    $('#' + MAP_ID).remove();
  }
  console.log("removed map: " + mapId);
}

function mapHolderElement() {
  console.log(MAP_ID + '_holder')
  return document.getElementById(MAP_ID + '_holder');
}

export function mapHolderData() {
  return mapHolderElement().dataset;
}

export function setUpSidePanelClose() {
  // Close side panel
  $('p.close > a').on('click', function(event) {
    event.preventDefault();
    $('p.close > a').closest('div.snippet').toggleClass('hide-screen');
    $('body').toggleClass('stop-scrolling');
  });
}
