
import { CLUSTER_OPTIONS, MAP_TILES_URL, MAP_TILES_OPTIONS, MARKER_OPTIONS, HISTORICAL_EVENT_MAP_DIV, HISTORICAL_EVENT_MAP_ID }  from './map_config.js';
import { addPopups, setUpSidePanelClose } from './map_common.js';

// Useful for debugging
// import { addLatLongClick, addLogOfCentre, addZoomLogger } from './map_common.js';

$(function () {
  if ($(HISTORICAL_EVENT_MAP_DIV).length) {
    fireRequestForJson();
    setUpSidePanelClose();
  }
});

function fireRequestForJson() {
  var propertiesElement = document.getElementById(HISTORICAL_EVENT_MAP_ID);

  var map_id = propertiesElement.getAttribute('map_id')
  // Add AJAX request for data
  var features = $.ajax({
    url: '/historical_events.json?map_id='+ map_id,
    dataType: "json",
    success: console.log("Data successfully loaded for historical events."),
    error: function(xhr) {
      console.log(xhr.statusText);
    }
  })

  $.when(features).done(function() {
    var latitude = propertiesElement.getAttribute('latitude')
    var longitude = propertiesElement.getAttribute('longitude')
    var zoomLevel = propertiesElement.getAttribute('zoom_level')
    var minZoom = propertiesElement.getAttribute('min_zoom')
    var topLeftCornerLatitude = propertiesElement.getAttribute('top_left_corner_latitude')
    var topLeftCornerLongitude = propertiesElement.getAttribute('top_left_corner_longitude')
    var bottomRightCornerLatitude = propertiesElement.getAttribute('bottom_right_corner_latitude')
    var bottomRightCornerLongitude  = propertiesElement.getAttribute('bottom_right_corner_longitude')

    var mapOptions = {
      zoomControl: false,
      minZoom: parseInt(minZoom),
      tap: false
    } // We add zoom controls later

    if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
      // No full screen controll
    } else {
      mapOptions.fullscreenControl = true;
      mapOptions.fullscreenControlOptions = { position: 'topleft' };
    }

    var maxBounds = L.latLngBounds(
      [topLeftCornerLatitude, topLeftCornerLongitude],
      [bottomRightCornerLatitude, bottomRightCornerLongitude]
    );

    var map = L.map(HISTORICAL_EVENT_MAP_ID, mapOptions);

    map.setMaxBounds(maxBounds);

    L.control.zoom({
      position:'topright'
    }).addTo(map);

    if (latitude.length && longitude.length && zoomLevel) {
      map.setView([latitude, longitude], zoomLevel);
    } else {
      map.setView([51.753738, -1.26346], 7);
    }

    // addLatLongClick(map);
    // addLogOfCentre(map);
    // addZoomLogger(map);

    var CartoDB_Positron = L.tileLayer(MAP_TILES_URL, MAP_TILES_OPTIONS).addTo(map);
    var featureLayer = networkGeoJSON(features);

    var markers = L.markerClusterGroup.layerSupport(CLUSTER_OPTIONS)

    markers.addTo(map);
    markers.checkIn(featureLayer);

    featureLayer.addTo(map);

    // Else pop ups do not work on safari
    map.tap.disable();
  });
}

function networkGeoJSON(features) {
  return L.geoJSON(features.responseJSON, {
    pointToLayer: addPoints,
  });
}

function addPoints(feature, latlng) {
  var properties = feature.properties;

  MARKER_OPTIONS.fillColor = properties.colour;
  var pointMarker = L.circleMarker(latlng, MARKER_OPTIONS).on('click', function (e) {
    $.ajax({
      url: "/historical_events/" + e.target.historicalEventId + ".json"
    }).done(function(data) {
      $('#snippet_title').html(data.title);
      $('#snippet_philosophers').html(data.philosopher_links);
      $('#snippet_description').html(data.description);
      $('#snippet_when').html(data.when);
      $('#snippet_location').html(data.location);
      $('#snippet_pages').html(data.pages);

      var overlay = $('div.snippet.overlay');
      overlay.toggleClass('hide-screen');
      $('body').toggleClass('stop-scrolling');
    });
  });

  pointMarker.historicalEventId = properties.historicalEventId;

  return pointMarker;
}
