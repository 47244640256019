import { MAP_TILES_URL, MAP_TILES_OPTIONS, DEFAULT_ICON, NETWORK_MAP_ID, NETWORK_MAP_DIV, CLUSTER_OPTIONS } from './map_config.js';
import { setUpSidePanelClose } from './map_common.js'

$(function () {
  if ($(NETWORK_MAP_DIV).length) {
    fireRequestForJson();
    setUpSidePanelClose();
  }
});

function fireRequestForJson() {
  var propertiesElement = document.getElementById(NETWORK_MAP_ID);

  // Add AJAX request for data
  var features = $.ajax({
    url: '/users.json',
    dataType: "json",
    success: console.log("Data successfully loaded for network users."),
    error: function(xhr) {
      console.log(xhr.statusText);
    }
  })

  $.when(features).done(function() {
    var latitude = propertiesElement.getAttribute('latitude');
    var longitude = propertiesElement.getAttribute('longitude');
    var zoomLevel = propertiesElement.getAttribute('zoom_level');
    var minZoom = propertiesElement.getAttribute('min_zoom');
    var topLeftCornerLatitude = propertiesElement.getAttribute('top_left_corner_latitude')
    var topLeftCornerLongitude = propertiesElement.getAttribute('top_left_corner_longitude')
    var bottomRightCornerLatitude = propertiesElement.getAttribute('bottom_right_corner_latitude')
    var bottomRightCornerLongitude  = propertiesElement.getAttribute('bottom_right_corner_longitude')

    var mapOptions = {
      zoomControl: false,
      minZoom: parseInt(minZoom)
    } // We add it later

    if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
      // No full screen controll
    }else{
      mapOptions.fullscreenControl = true;
      mapOptions.fullscreenControlOptions = { position: 'topleft' };
    }

    var maxBounds = L.latLngBounds(
      [topLeftCornerLatitude, topLeftCornerLongitude],
      [bottomRightCornerLatitude, bottomRightCornerLongitude]
    );

    var map = L.map(NETWORK_MAP_ID, mapOptions);

    L.control.zoom({
      position:'topright'
    }).addTo(map);

    if (latitude.length && longitude.length && zoomLevel) {
      map.setView([latitude, longitude], zoomLevel);
    } else {
      map.setView([51.753738, -1.26346], 7);
    }

    var CartoDB_Positron = L.tileLayer(MAP_TILES_URL, MAP_TILES_OPTIONS).addTo(map);
    var featureLayer = networkGeoJSON(features);

    var markers = L.markerClusterGroup.layerSupport(CLUSTER_OPTIONS)

    markers.addTo(map);
    markers.checkIn(featureLayer);

    featureLayer.addTo(map);

    map.setMaxBounds(maxBounds);

    // Else pop ups do not work on safari
    if (map.tap) {
      map.tap.disable();
    }
  });
}

function networkGeoJSON(features) {
  return L.geoJSON(features.responseJSON, {
    pointToLayer: addPoints,
  });
}

function addPoints(feature, latlng) {
  var properties = feature.properties;

  var options = {
    popupAnchor: [0, 0],
    className: properties.icon_class,
    html: '<div class="leaflet-marker-label">' + properties.label + '</div>'
  }

  var actualIcon = L.divIcon(options);

  var pointMarker = L.marker(latlng, { icon: actualIcon }).on('click', function (e) {
    console.log(e.target.userId);
    $.ajax({
      url: "/users/" + e.target.userId + ".json"
    }).done(function(data) {

      updateSideBar('.user-name', data.user_name, '#snippet_user_name');
      updateSideBar('.user-email', data.email_address, '#snippet_email_address');
      updateSideBar('.user-location', data.location, '#snippet_location');
      updateSideBar('.user-profile', data.profile, '#snippet_profile');
      updateSideBar('.user-publications', data.publications, '#snippet_publications');
      updateSideBar('.user-descriptions', data.descriptions, '#snippet_descriptions');
      updateSideBar('.user-philosophers', data.philosopher_links, '#snippet_philosophers');

      var userId = data.id;

      var overlay = $('div.snippet.overlay');
      overlay.toggleClass('hide-screen');
      $('body').toggleClass('stop-scrolling');
    });
  });

  pointMarker.userId = properties.user_id;

  return pointMarker;
}

function updateSideBar(className, data, idForData) {
  if(data) {
    $(idForData).html(data);
    $(className).removeClass('d-none');
  } else {
    $(className).addClass('d-none');
  }
}
