import { MAP_ID } from './map_config.js';
import { createMap, removeMap } from './map_common.js';

$(function () {
  console.log('Hello World from Select2');

  var select2OptionsTruncForUser = {
    theme: 'bootstrap-5',
    minimumInputLength: 3,
    ajax: {
      url: '/locations?trunc_for_user=true',
      delay: 250,
      dataType: 'json',
      processResults: function (data) {
        // Transforms the top-level key of the response object from 'items' to 'results'
        // console.log(data.items);
        return {
          results: data.items
        };
      }
    },
  };

  var select2OptionsFull = {
    theme: 'bootstrap-5',
    minimumInputLength: 3,
    ajax: {
      url: '/locations',
      delay: 250,
      dataType: 'json',
      processResults: function (data) {
        // Transforms the top-level key of the response object from 'items' to 'results'
        // console.log(data.items);
        return {
          results: data.items
        };
      }
    },
  };

  $('.select2.full_location').select2(select2OptionsFull);

  $('.select2.trunc_for_user').select2(select2OptionsTruncForUser);

  $('.select2').on('select2:select', function (e) {
    console.log('selected!');

    var selected_data = $(e.target).select2('data')[0];
    var latitude = selected_data.latitude;
    var longitude = selected_data.longitude;

    var historical_event_latitude_field = document.getElementById("historical_event_latitude");
    var historical_event_longitude_field = document.getElementById("historical_event_longitude");

    if (historical_event_latitude_field && historical_event_longitude_field) {
      historical_event_latitude_field.value = latitude;
      historical_event_longitude_field.value = longitude;
    }

    setUpMap(MAP_ID, latitude, longitude);
  });
});

function setUpError(mapId, error) {
  console.log(error);
  $('p.map-error').html('Sorry, there was a problem finding the location, please try using a different search term, or try again later');
  $('p.map-error').addClass('alert alert-warning')
  removeMap(mapId)
}

function clearError() {
  $('p.map-error').html('');
  $('p.map-error').removeClass('alert alert-warning')
}

function setUpMap(mapId, latitude, longitude) {
  var zoomLevel = 13;
  clearError();
  removeMap(mapId);

  createMap(latitude, longitude, mapId)
}
